export default function optimizeImage(url: string, size?: string): string {
  let optimizedUrl = '';
  if (!url) return "";
  if (String(url).endsWith(".svg")) return url;

  optimizedUrl = url + "/m/";

  if(size && size?.length > 0){
    optimizedUrl += size;
  }
  
  return optimizedUrl;
}
